/* eslint-disable import/newline-after-import */
/* eslint-disable eqeqeq */
/* eslint-disable arrow-parens */
/* eslint-disable comma-dangle */
/* eslint-disable object-shorthand */
/* eslint-disable no-undef */
/* eslint-disable semi */
/* eslint-disable indent */
/* eslint-disable quotes */
// axios
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data() {
    return {
      deleteResult: true,
    };
  },
  methods: {
    async callApi(method, url, dataObj) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      try {
        return await axios({
          method: method,
          url: url,
          data: dataObj,
        });
      } catch (e) {
        this.$Loading.error();
        return e.response;
      }
    },
    DeleteAlert(url, dataObj) {
      this.$Loading.start();
      axios
        .post(url, dataObj)
        .then((res) => {
          if (res.status == 200) {
            this.$Loading.finish();
            this.s("Data Successfully Deleted :)");
          } else {
            this.$Loading.error();
            this.sw();
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((e) => {
          this.$Loading.error();
          this.sw();
        });
    },
    // sw(title = "Danger") {},

    i(description, title = "Hey!") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: description,
          variant: "info",
        },
      });
    },
    s(description, title = "Great!") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: description,
          variant: "success",
        },
      });
    },
    w(description, title = "Oops!") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: description,
          variant: "warning",
        },
      });
    },
    e(description, title = "Hey!") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: description,
          variant: "danger",
        },
      });
    },
    sw(title = "Oops!") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: "Something was wrong",
          variant: "danger",
        },
      });
    },

    //    async commonPermission() {
    //       try {
    //          return await axios({
    //              method: 'post',
    //              url: "/app/check/permission",
    //            });
    //        } catch (e) {
    //           return e.response
    //        }
    //   },

    // DeleteData(method, url, dataObj) {
    //   Swal.fire({
    //       title: 'Are you sure?',
    //       text: "You won't to delete it!",
    //       icon: 'warning',
    //       showCancelButton: true,
    //       confirmButtonColor: '#3085d6',
    //       cancelButtonColor: '#d33',
    //       confirmButtonText: 'Yes, delete it!'
    //       }).then((result) => {
    //          axios({
    //             method: method,
    //             url: url,
    //             data: dataObj
    //          });
    //       })
    // },
  },
};
