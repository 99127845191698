import Vue from "vue";
import Vuex from "vuex";
import router from "../router";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    base_url: "http://127.0.0.1:8000/",
    token: localStorage.getItem("token") || null,
    user_name: localStorage.getItem("user_name") || null,
    affiliate_token: localStorage.getItem("affiliate_token") || null,

    // getOptionCategory: {},
  },
  getters: {
    loggedIn(state) {
      return state.token !== null;
    },
    // getOptionCategory(state) {
    //   return state.getOptionCategory;
    // },
  },
  mutations: {
    setToken(state, data) {
      state.token = data.token;
      state.user_name = data.name;
      state.affiliate_token = data.affiliate_token;
    },
    removeToken(state) {
      state.token = null;
      state.user_name = null;
      state.affiliate_token = null;
    },

    // getOptionCategory(state, data) {
    //   return (state.getOptionCategory = data);
    // },
  },
  actions: {
    removeToken(context) {
      context.commit("removeToken");
    },
    setToken(context, data) {
      context.commit("setToken", data);
    },

    CheckAuth(content) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");
      axios
        .post("/affiliate/user/check")
        .then((res) => {
          if (!res.data.status) {
            localStorage.removeItem("token");
            content.commit("removeToken");
            router.push("/login");
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            localStorage.removeItem("token");
            content.commit("removeToken");
            router.push("/login");
          }
        });
    },

    // getOptionCategory(content) {
    //   axios
    //     .post("/app/option/category/get")
    //     .then((res) => {
    //       content.commit("getOptionCategory", res.data.categories);
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
});
