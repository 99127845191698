/* eslint-disable arrow-parens */
/* eslint-disable semi */
/* eslint-disable quotes */
import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/dashboard/Dashboard.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/profile/Profile.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Profile",
        breadcrumb: [
          {
            text: "Profile",
            active: true,
          },
        ],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },
    {
      path: "/auth/manage",
      name: "auth-manage",
      component: () => import("@/views/Registration.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },
    // {
    //   path: "/verify/account",
    //   name: "verify-account",
    //   component: () => import("@/views/Verification.vue"),
    //   meta: {
    //     visitor: true,
    //     layout: "full",
    //   },
    // },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  if (localStorage.getItem("token")) {
    store.dispatch("CheckAuth");
  }
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (!store.getters.loggedIn) {
      next({ name: "login" });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.visitor)) {
    if (store.getters.loggedIn) {
      next({ name: "dashboard" });
    } else {
      next();
    }
  }
});

export default router;
